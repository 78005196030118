<template>
  <standard-layout v-if="cart">
    <template v-slot:heading><h1>Deine Daten</h1></template>
    <template v-slot:main>
      <div class="row">
        <div class="col-12">
          <customer-editor v-model="cart.customer"
                           submit-label="Zur Bezahlung"
                           @save="save"/>
        </div>
      </div>
    </template>
  </standard-layout>
</template>

<script>
import CustomerEditor from "@/components/checkout/CustomerEditor";
import {mapActions, mapGetters} from "vuex";
import * as Sentry from '@sentry/vue'

export default {
  components: {CustomerEditor},
  computed: {
    ...mapGetters(['cart'])
  },
  methods: {
    ...mapActions(['assignCustomerToCart']),
    save() {
      this.assignCustomerToCart(this.cart.customer)
          .then(() => this.$router.push({name: 'payment', params: {cartId: this.cart.id}}))
          .catch(error => this.handleError(error))
    }
  },
  activated() {
    if (this.$route.params.cart) {
      this.$store.dispatch('initCart', this.$route.params.cart)
          .then((cart) => {
            const purchaseType = cart.reservationCart ? 'reservation' : (cart.voucherCart ? 'voucher' : 'misc')
            this.$gtag.event(purchaseType + '_intent', {})
          }).catch(error => {
            this.handleError(error)
            this.$router.push({name: 'editReservation'})
          })
    } else {
      Sentry.captureMessage('navigated bookingConfirm without cart query parameter')
      this.$router.push('/')
    }
  }
}

</script>
